/* eslint-disable */
import { useRef } from 'react';
import { getIn } from 'formik';

// MUI
import {
  Box,
  FormHelperText,
  Typography,
} from '@mui/material';

import { Editor } from '@tinymce/tinymce-react';

const RichText = (props) => {
  const {
    formik,
    getApiError,
    label,
    name,
    showFileBrowser
  } = props;

  const richTextEditorRef = useRef(null);

  return (
    <>
      <Typography variant="h6">
        {label}
      </Typography>
      <Editor
        apiKey="27qvj8rdc5rz6hoxb7bemmorbe6scbt3i0c7gqycmz3na4cg"
        initialValue={getIn(formik.values, name)}
        onInit={(event, editor) => { richTextEditorRef.current = editor; }}
        onBlur={() => {
          if (richTextEditorRef.current) {
            formik.setFieldValue(name, richTextEditorRef.current.getContent());
          }
        }}
        init={{
          link_rel_list: [
            { title: 'Follow', value: 'follow' },
            { title: 'No Follow', value: 'nofollow' },
          ],
          height: 300,
          menubar: false,
          plugins: 'lists link image code fullscreen',
          toolbar: 'undo redo | blocks | bold italic underline | bullist numlist | blockquote' +
            ' | alignleft aligncenter alignright | link image | removeformat | code fullscreen',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          inline_styles: false,
          formats: {
            underline: { inline: 'u', exact : true }
          },
          // eslint-disable-next-line no-shadow
          file_picker_callback: (callback, value, meta) => {
            if (meta.filetype === 'image') {
              if (showFileBrowser) showFileBrowser(callback);
            }
          }
        }}
      />
      {Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))) && (
        <Box sx={{ mt: 2 }}>
          <FormHelperText error>
            {getApiError(name) || getIn(formik.errors, name)}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};

export default RichText;
